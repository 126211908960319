import React from 'react';

function Footer() {
  return (
    <footer className="tc-footer-style3">
      <div className="container">
        
        <div className="main-content">
          <div className="row">
          <div className="col-lg-2">
            <a href="/" className="foot-logo mb-5 mb-lg-0">
              <img src="/home3_construction/assets/img/logo.png" alt="" style={{ width: '450px', height: 'auto' }} />
            </a>
          </div>
            <div className="col-lg-3 offset-lg-2 mb-5 mb-lg-0">
              <div className="adresses">
              <div className="adress-card mb-70">
  <h5 className="fsz-24 text-uppercase fw-500 lh-5 mb-4">
    Financial District, Hyderabad
  </h5>
  <ul className="color-999 lh-5">
    <li>
      <a href="https://maps.app.goo.gl/VmaqDDkbfLeMMd5v5">
        The District, Level 12, Near Regus
      </a>
    </li>
    <li>
      <a href="mailto:sarvaraya@casafortune.in"> sarvaraya@casafortune.in </a>
    </li>
    <li>
      <a href="tel:+919550485888"> +91 95504 85888 </a>
    </li>
  </ul>
</div>

                
              </div>
            </div>
            
            <div className="col-lg-2">
              <div className="social">
                <h5 className="fsz-24 text-uppercase fw-500 lh-5 mb-4">
                  Social
                </h5>
                <div className="social-links">
                  <a href="#">
                    <i className="lab la-twitter"></i>
                  </a>
                  <a href="#">
                    <i className="lab la-facebook-f"></i>
                  </a>
                  <a href="#">
                    <i className="lab la-instagram"></i>
                  </a>
                  <a href="#">
                    <i className="lab la-linkedin-in"></i>
                  </a>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="foot">
          <div className="row align-items-center">
            <div className="col-lg-4">
              
            </div>
            <div className="col-lg-4 mt-4 mt-lg-0">
              <p className="color-999">
                © 2024 CASAFORTUNE. All Right Reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
