import React from 'react';
import demosData from '../../data/main-page/demos';

function Header() {
  return (
    <header className="tc-header-preview">
      <div
        className="slider-card"
        style={{
          position: 'relative',
          height: '600px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {/* Background image */}
        <div className="img" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 0 }}>
          <img
            src="/home_preview/assets/img/test.jpg"
            alt="Background"
            className="img-cover"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </div>

        {/* Overlay for contrast */}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'rgba(0, 0, 0, 0.4)',
            zIndex: 2,
          }}
        />

        
        {/* Cards Section */}
        <section className="tc-demos" style={{ position: 'relative', zIndex: 5, width: '100%' }}>
          <div className="container">
            <div className="cards">
              <div className="row justify-content-center card-section">
                {demosData.map((item, i) => (
                  <div key={i} className="col-lg-3 col-md-4 col-sm-6 col-12 card-column" style={{ padding: '10px' }}>
                    <a
                      href={item.link}
                      className="demo-card"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        display: 'block',
                        textAlign: 'center',
                        background: '#fff',
                        borderRadius: '10px',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s',
                        overflow: 'hidden',
                        position: 'relative',
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.transform = 'scale(1.05)';
                        e.currentTarget.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.2)';
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.transform = 'scale(1)';
                        e.currentTarget.style.boxShadow = '0 4px 10px rgba(0, 0, 0, 0.1)';
                      }}
                    >
                      {/* Image with hover zoom */}
                      <div
                        className="img"
                        style={{
                          height: '350px',
                          overflow: 'hidden',
                        }}
                      >
                        <img
                          src={item.img}
                          alt={item.title}
                          className="img-cover"
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            transition: 'transform 0.3s ease',
                          }}
                        />
                      </div>

                      {/* Card info section */}
                      <div
                        className="info"
                        style={{
                          padding: '10px 0',
                          background: '#fff',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: '0 0 10px 10px',
                        }}
                      >
                        <h6
                          className="fsz-16 fw-bold"
                          style={{ fontSize: '1rem', marginBottom: '0', padding: '0' }}
                        >
                          {item.title}
                        </h6>
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Custom CSS for logo and card section */}
      <style>
        {`
          .logo-container {
            position: absolute;
            top: 20px; /* Adjust top positioning as needed */
            left: 20px; /* Adjust left positioning as needed */
            z-index: 10;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 200px; /* Maximum width for the logo */
          }

          .responsive-logo {
            width: 100%;
            height: auto;
            max-width: 150px; /* Maximum size of the logo */
            max-height: 50px;
          }

          @media (max-width: 768px) {
            .logo-container {
              max-width: 120px; /* Reduce container size for smaller screens */
            }
            .responsive-logo {
              max-width: 100px; /* Smaller logo for smaller screens */
              max-height: 40px;
            }
          }

          @media (max-width: 480px) {
            .logo-container {
              max-width: 100px;
            }
            .responsive-logo {
              max-width: 80px; /* Further reduce size on mobile */
              max-height: 30px;
            }
          }

          @media (max-width: 768px) {
            .card-section {
              display: none; /* Hide card section on mobile */
            }
          }
        `}
      </style>
    </header>
  );
}

export default Header;
