import React from 'react';

function About() {
  return (
    <section className="tc-about-style7">
      <div className="container">
        <div className="row gx-0 justify-content-between">
          <div className="col-lg-3">
            <div className="rotate-box" data-speed="1" data-lag="0.4">
              <a
                href="#"
                className="rotate-circle fsz-30 rotate-text d-inline-block text-uppercase"
              >
                <svg className="textcircle" viewBox="0 0 500 500">
                  <defs>
                    <path
                      id="textcircle"
                      d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
                    ></path>
                  </defs>
                  <text>
                    <textPath xlinkHref="#textcircle" textLength="900">
                      CASAFORTUNE DEVELOPERS PRIVATE LIMITED
                    </textPath>
                  </text>
                </svg>
              </a>
              <img
                src="/innerpages/assets/img/leafs.png"
                alt=""
                className="icon"
              />
            </div>
          </div>
          <div className="col-lg-8">
            <div className="info wow fadeInUp slow" data-wow-delay="0.2s">
              
              <div className="text fsz-20 fw-500 mb-20">
              Established in 2010, CASA FORTUNE stands out as a prominent player in the Indian occupancy services sector. Our comprehensive offerings encompass corporate interior/exterior design, turnkey interiors, civil and interior services, MEP services, general contracting services, and imported furniture. As India continues to attract businesses worldwide due to its growing economy, the demand for top-tier occupancy services and interior design firms has never been greater. Corporations are increasingly relying on reputable interior design firms, architects, and contractors to infuse a touch of sophistication and creativity into their corporate spaces, aligning them seamlessly with their brand’s ethos. Our Interior Design solutions and General Contracting services are dedicated to delivering a harmonious blend of aesthetics, ergonomics, and productivity in workplace environments for our valued clients.
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </section>
  );
}

export default About;
