import React from 'react';
import data from '../../../data/innerpages/portfolio/latestcases'; // Import your data

function LatestCases() {
  return (
    <section className="tc-latest-cases-style1">
      <div className="container">
        <div className="content">
          <h3 className="fsz-45 text-capitalize mb-90 js-splittext-lines">
            Latest Projects
          </h3>
          <div className="cases">
            <div className="cases-content wow fadeInUp slow" data-wow-delay="0.4s">
              <div className="row">
                {data.map((item, i) => (
                  <div key={i} className={`col-lg-4 mix-item ${item.subTitle}`}>
                    <div className="case-card">
                      {/* Use the link field to link the project */}
                      <a href={item.link} className="img">
                        <img src={item.img} alt={item.title} className="img-cover" />
                      </a>
                      <div className="info">
                        <div className="tags mb-30">
                          <a href={item.link}> {item.subTitle} </a>
                        </div>
                        <h3 className="title fsz-35 mb-20">
                          <a href={item.link} className="hover-orange1">
                            {item.title}
                          </a>
                        </h3>
                        <div className="text color-666">
                          {/* Remove HTML from description or replace <br /> with real line breaks */}
                          {item.desc.replace('<br />', '\n')}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LatestCases;
