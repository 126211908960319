import React from 'react';

function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg tc-navbar-style3">
      <div className="container-fluid d-flex justify-content-between align-items-center">
        <div className="col-6 col-lg-4 p-0">
          <a className="navbar-brand" href="#">
            <img
              src="/home3_construction/assets/img/logo.png"
              alt="Logo"
              className="logo"
            />
          </a>
        </div>

        <div className="col-lg-4 p-0 d-flex justify-content-end">
          <div className="side-nav">
            <a href="#" className="ico-link side_menu_btn">
              <i className="la la-grip-lines"></i>
            </a>
          </div>
        </div>
      </div>

      {/* Embedded CSS to manage logo responsiveness */}
      <style jsx>{`
        .navbar {
          padding: 10px 0; /* Add space to prevent logo overlap */
        }

        .logo {
          width: 100%;
          height: auto;
          max-width: 150px; /* Adjust this value to set a maximum width for the logo */
        }

        /* Responsive adjustments */
        @media (max-width: 768px) {
          .logo {
            max-width: 120px; /* Smaller logo on mobile devices */
          }
        }

        @media (max-width: 576px) {
          .logo {
            max-width: 100px; /* Even smaller logo for very small screens */
          }
        }
      `}</style>
    </nav>
  );
}

export default Navbar;
