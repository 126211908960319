import React from 'react';

function ContactInfo() {
  return (
    <section className="tc-contact-info-style1">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="info-cards">
              <div className="row">
                <div className="col-lg-6">
                  <div className="item mt-50">
                    <h6 className="fsz-24 mb-15 fw-bold"> Financial District </h6>
                    <ul className="color-666 lh-6">
                      <li>
                        <a href="https://maps.app.goo.gl/VmaqDDkbfLeMMd5v5"> Level 12, The District, Near Regus, Financial District, Hyderabad </a>
                      </li>
                      <li>
                        <a href="mailto:sarvara@casafortune.in"> sarvaraya@casafortune.in </a>
                      </li>
                      <li>
                        <a href="tel:+919550485888"> +91 95504 85888 </a>
                      </li>
                    </ul>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="social-icons mt-50 text-lg-end">
              <a href="#">
                <i className="fab fa-x-twitter"></i>
              </a>
              <a href="#">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="#">
                <i className="fab fa-linkedin-in"></i>
              </a>
             
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactInfo;
