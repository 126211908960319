import React from 'react';

function About() {
  return (
    <section className="tc-about-style3">
      <div className="top-info">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="info">
                <h5 className="fsz-30 text-uppercase mb-80"> Since 2010 </h5>
                <div className="milestones">
                  {/* Milestone 1: From 2010 to 2015 */}
                  <div className="milestone-item">
                    <div className="milestone-icon"></div>
                    <div className="milestone-content">
                      <h6 className="fsz-16 text-uppercase mb-5">2010 - 2015</h6>
                      <p className="fsz-14 mb-10">
                        We began our journey as a civil contractor, focusing on residential and commercial projects.
                      </p>
                    </div>
                  </div>
                  {/* Milestone 2: By 2015 */}
                  <div className="milestone-item">
                    <div className="milestone-icon"></div>
                    <div className="milestone-content">
                      <h6 className="fsz-16 text-uppercase mb-5">2015</h6>
                      <p className="fsz-14 mb-10">
                        Transitioned into a finishing contractor, serving builders and developers until 2017.
                      </p>
                    </div>
                  </div>
                  {/* Milestone 3: 2017 */}
                  <div className="milestone-item">
                    <div className="milestone-icon"></div>
                    <div className="milestone-content">
                      <h6 className="fsz-16 text-uppercase mb-5">2017</h6>
                      <p className="fsz-14 mb-10">
                        Expanded expertise by establishing Creatif, specializing in interior design and offering comprehensive turnkey contracting services.
                      </p>
                    </div>
                  </div>
                  {/* Milestone 4: 2020 - 2023 */}
                  <div className="milestone-item">
                    <div className="milestone-icon"></div>
                    <div className="milestone-content">
                      <h6 className="fsz-16 text-uppercase mb-5">2020 - 2023</h6>
                      <p className="fsz-14 mb-10">
                        Launched a full-scale turnkey operation that encompassed building finishing services, including fire safety systems, MEP, HVAC, infrastructure development, and façade work.
                      </p>
                    </div>
                  </div>
                </div>
                <a
                  href="/innerpages/about"
                  className="butn borderd mt-70 bg-white py-2 px-3 text-uppercase ltspc-1 hover-bg-yellow1"
                >
                  <span>
                    About us <i className="small ms-1 ti-arrow-top-right"></i>
                  </span>
                </a>
              </div>
            </div>
            <div className="col-lg-8 offset-lg-1">
              <div className="numbers-boxes">
                <div className="sm-card js-splittext-lines" data-speed="1" data-lag="0.4">
                  <h4 className="fsz-70 mb-30"> 8+  </h4>
                  <p className="p text-uppercase">Cities</p>
                </div>
                <div className="md-card js-splittext-lines" data-speed="1" data-lag="0.8">
                  <h3 className="fsz-90 mb-90 fw-500"> 100+ </h3>
                  <p className="p text-uppercase">completed projects</p>
                </div>
                <div className="lg-card js-splittext-lines" data-speed="1" data-lag="0.2">
                  <h2 className="fw-500"> 14+ </h2>
                  <h6 className="text-uppercase text-end fsz-18 lh-4">
                    years of <br /> experience
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="/home3_construction/assets/img/about_pat.png"
          alt=""
          className="pattern"
        />
      </div>
      <div className="img-content wow">
        <img
          src="/home3_construction/assets/img/about.jpg"
          alt=""
          className="img-cover main-img"
        />
      </div>

      {/* Embedded CSS for Milestone Timeline with Smaller Text/Boxes */}
      <style jsx>{`
        .milestones {
          position: relative;
          margin: 0 auto;
          padding: 15px 0; /* Reduced padding */
          width: 100%;
        }
        .milestone-item {
          display: flex;
          position: relative;
          margin-bottom: 20px; /* Reduced space between items */
        }
        .milestone-icon {
          width: 10px; /* Reduced size */
          height: 10px;
          background-color: #f3b600;
          border-radius: 50%;
          position: absolute;
          left: -15px; /* Adjusted for smaller size */
          top: 5px;
        }
        .milestone-content {
          padding: 5px 15px; /* Reduced padding */
          background: #fff;
          border: 1px solid #ccc;
          border-radius: 5px;
          position: relative;
        }
        .milestones::before {
          content: '';
          position: absolute;
          width: 1px; /* Reduced width */
          background-color: #ccc;
          top: 0;
          bottom: 0;
          left: -10px;
        }
        .fsz-16 {
          font-size: 16px; /* Smaller heading font */
          text-transform: uppercase;
          margin-bottom: 5px; /* Reduced margin */
        }
        .fsz-14 {
          font-size: 14px; /* Smaller text font */
          margin-bottom: 10px; /* Reduced margin */
        }

        /* Responsive adjustment */
        @media (max-width: 768px) {
          .milestone-item {
            flex-direction: column;
            align-items: flex-start;
          }
          .milestone-icon {
            left: 0;
            top: -5px;
            margin-bottom: 10px;
          }
          .milestones::before {
            left: 0;
            transform: none;
          }
        }
      `}</style>
    </section>
  );
}

export default About;
